import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BannerMain from '../components/BannerMain'
import ServiciosLista from '../components/ServiciosLista'
import CardSalon from '../components/CardSalon'
import CardBeneficio from '../components/CardBeneficio'
import BannerAgenda from '../components/BannerAgenda'

import salon1 from '../images/thumb-plaza-cataluna.jpg'
import salon2 from '../images/thumb-salon-jordi.jpg'
import beneficio1 from '../images/instalaciones-lujo.jpg'
import beneficio2 from '../images/hasta-1000.jpg'
import beneficio3 from '../images/doble-meseros.jpg'
import beneficio4 from '../images/conoce-cocina.jpg'

const IndexPage = () => (
	<Layout>

		<SEO title="El mejor día de tu vida" keywords={[`salones de eventos apodaca`, `eventos sociales apodaca`, `renta salones`]} />

		<BannerMain />

		<div className="mt-5">
			<ServiciosLista/>
		</div>

		<section className="salones-block">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Instalaciones</h3>
				<p className="heading-caption">Salones cuya belleza habla por sí sola</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Plaza Cataluña"
							subtitulo="Capacidad: 500 personas"
							imgsrc={salon1}
							link="/plaza-cataluna"
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Saint Jordi"
							subtitulo="Capacidad: 300 personas"
							imgsrc={salon2}
							link="/saint-jordi"
						/>
					</div>
				</div>
			</div>
		</section>

		<section className="beneficios-block">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Beneficios</h3>
				<p className="heading-caption">Descubre la diferencia Barcelona</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Instalaciones de lujo"
							caption="Nuestras instalaciones son altamente elogiadas por su belleza y sus acabados de lujo."
							imgsrc={beneficio1}
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Hasta 1000 personas"
							caption="Somos la única opción en Apodaca, Nuevo León, con capacidad para atender a 1000 personas."
							imgsrc={beneficio2}
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Doble de meseros que en otros salones"
							caption="Somos el único salón que cuenta con 1 mesero por cada 2 mesas."
							imgsrc={beneficio3}
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Conoce nuestra cocina y te sorprenderás"
							caption="Utilizamos ingredientes de la más alta calidad para crear los mejores sabores para tu evento."
							imgsrc={beneficio4}
						/>
					</div>
				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default IndexPage
