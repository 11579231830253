import React, {Component} from "react"
import { Link } from "gatsby"

import banner1 from '../images/banner-1.jpg'

class BannerMain extends Component{
	render(){
		return(
			<section className="banner-main">
				<div className="slide">
					<div className="inner">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<h1 className="title">
										<small>Bodas</small>
										<span>El mejor día de tu vida.</span>
									</h1>
									<p className="mb-4"><strong>Bienvenido a Barcelona Eventos.</strong> Ven y celebra tu día especial en cualquiera de nuestros hermosos salones y disfruta de nuestro selecto servicio de catering. Nuestra pasión es hacer de tu evento un gran placer. </p>
									<Link to="/nosotros" className="btn btn-solid azul extra-padding">Ver más</Link>
								</div>
							</div>
						</div>
					</div>
					<img className="poster" src={banner1} alt="Bodas" width="1440" height="670"/>
				</div>
			</section>
		)
	}
}

export default BannerMain
